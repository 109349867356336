import { default as aboutggr3etxHM3Meta } from "/home/webpages/hochwacht-frontend-test/pages/about.vue?macro=true";
import { default as _91id_93M97zv0p7ZQMeta } from "/home/webpages/hochwacht-frontend-test/pages/agegroups/[id].vue?macro=true";
import { default as contacteGMMDejP9YMeta } from "/home/webpages/hochwacht-frontend-test/pages/contact.vue?macro=true";
import { default as documentsEBUURs18F4Meta } from "/home/webpages/hochwacht-frontend-test/pages/documents.vue?macro=true";
import { default as donationZi6D19oSyjMeta } from "/home/webpages/hochwacht-frontend-test/pages/donation.vue?macro=true";
import { default as equipmentmGof1P13RUMeta } from "/home/webpages/hochwacht-frontend-test/pages/equipment.vue?macro=true";
import { default as faqp9BuOtBJFeMeta } from "/home/webpages/hochwacht-frontend-test/pages/faq.vue?macro=true";
import { default as _91id_93b4vDsrPeMPMeta } from "/home/webpages/hochwacht-frontend-test/pages/galleries/[id].vue?macro=true";
import { default as indexb4NOwAvjcjMeta } from "/home/webpages/hochwacht-frontend-test/pages/galleries/index.vue?macro=true";
import { default as impressumlpLakklJh5Meta } from "/home/webpages/hochwacht-frontend-test/pages/impressum.vue?macro=true";
import { default as indexte47ghYlbVMeta } from "/home/webpages/hochwacht-frontend-test/pages/index.vue?macro=true";
import { default as privacypolicysgwu6KNtVuMeta } from "/home/webpages/hochwacht-frontend-test/pages/privacypolicy.vue?macro=true";
import { default as rental9a4O4suHajMeta } from "/home/webpages/hochwacht-frontend-test/pages/rental.vue?macro=true";
import { default as rentalimagesrtt9kzCDHnMeta } from "/home/webpages/hochwacht-frontend-test/pages/rentalimages.vue?macro=true";
import { default as _91_46_46_46slug_93Mwnt3oRxDwMeta } from "/home/webpages/hochwacht-frontend-test/pages/resources/[...slug].vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/about.vue")
  },
  {
    name: "agegroups-id",
    path: "/agegroups/:id()",
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/agegroups/[id].vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/contact.vue")
  },
  {
    name: "documents",
    path: "/documents",
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/documents.vue")
  },
  {
    name: "donation",
    path: "/donation",
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/donation.vue")
  },
  {
    name: "equipment",
    path: "/equipment",
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/equipment.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/faq.vue")
  },
  {
    name: "galleries-id",
    path: "/galleries/:id()",
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/galleries/[id].vue")
  },
  {
    name: "galleries",
    path: "/galleries",
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/galleries/index.vue")
  },
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/impressum.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/index.vue")
  },
  {
    name: "privacypolicy",
    path: "/privacypolicy",
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/privacypolicy.vue")
  },
  {
    name: "rental",
    path: "/rental",
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/rental.vue")
  },
  {
    name: "rentalimages",
    path: "/rentalimages",
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/rentalimages.vue")
  },
  {
    name: "resources-slug",
    path: "/resources/:slug(.*)*",
    component: () => import("/home/webpages/hochwacht-frontend-test/pages/resources/[...slug].vue")
  }
]